<template>
  <div class="app-footer flex-0">Copyright &copy; 超智云 AI</div>
</template>

<script>
export default {
  name: "app-footer",
};
</script>

<style lang="scss" scoped>
.app-footer {
  padding: 20px 0;
  text-align: center;
  color: #afafaf;
  font-size: 14px;
}
</style>
