<template>
  <div class="app-body">
    <div class="privacy-page">
      <p class="user-page-bg"></p>
      <p class="user-page-title" style="font-size: 22px">
        <b>超智云AI 订阅会员协议</b>
      </p>
      <div class="user-page-body">
        <span data-v-3af7478a=""
          ><b>本</b><b>协</b><b>议</b><b>是</b><b>由</b><b>木</b><b>风</b
          ><b>未</b><b>来</b><b>科</b><b>技</b><b>有</b><b>限</b><b>公司</b
          ><b>（</b><b>以下</b><b>简</b><b>称</b><b>“</b><b>本</b><b>公司</b
          ><b>”</b><b>）</b><b>为</b><b>会</b><b>员</b><b>提</b><b>供</b
          ><b>的</b><b>相关</b><b>订</b><b>购</b><b>服务</b><b>的</b><b>合</b
          ><b>法</b><b>协</b><b>议</b><b>。</b><b>会</b><b>员</b><b>通过</b
          ><b>在</b><b>本</b><b>公司</b><b>官</b><b>方</b><b>网</b><b>站</b
          ><b>或</b><b>移</b><b>动</b><b>应</b><b>用</b><b>程序</b><b>上</b
          ><b>进行</b><b>订</b><b>购</b><b>并</b><b>支付</b><b>费</b><b>用</b
          ><b>，</b><b>即</b><b>表示</b><b>同</b><b>意</b><b>遵</b><b>守</b
          ><b>本</b><b>协</b><b>议</b><b>的</b><b>所有</b><b>条</b><b>款</b
          ><b>和</b><b>条件</b><b>。</b><b>本</b><b>协</b><b>议</b><b>具</b
          ><b>有</b><b>合</b><b>同</b><b>效</b><b>力</b><b>，</b><b>双</b
          ><b>方</b><b>均</b><b>有</b><b>权</b><b>根</b><b>据</b><b>本</b
          ><b>协</b><b>议</b><b>的</b><b>规</b><b>定</b><b>要</b><b>求</b
          ><b>履</b><b>行</b><b>相</b><b>应</b><b>的</b><b>义</b><b>务</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>一</b><b>、</b><b>订</b><b>购</b><b>服务</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>1</b><b>.</b><b>1</b><b> </b><b>会</b><b>员</b><b>订</b><b>购</b
          ><b>服务</b><b>包</b><b>括</b><b>但</b><b>不</b><b>限</b><b>于</b
          ><b>：</b><b>访</b><b>问</b><b>本</b><b>公司</b><b>的</b><b>在线</b
          ><b>服务</b><b>、</b><b>软</b><b>件</b><b>使用</b><b>功能</b><b>、</b
          ><b>会</b><b>员</b><b>专</b><b>属</b><b>权</b><b>益</b><b>等</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>1</b><b>.</b><b>2</b><b> </b><b>本</b><b>公司</b><b>保</b><b>留</b
          ><b>单</b><b>方</b><b>面</b><b>决</b><b>定</b><b>对</b><b>订</b
          ><b>购</b><b>服务</b><b>的</b><b>包</b><b>含</b><b>内容</b><b>、</b
          ><b>提</b><b>供</b><b>方式</b><b>、</b><b>服务</b><b>区</b><b>域</b
          ><b>、</b><b>费</b><b>用</b><b>等</b><b>进行</b><b>调</b><b>整</b
          ><b>、</b><b>变</b><b>更</b><b>、</b><b>中</b><b>断</b><b>或</b
          ><b>终</b><b>止</b><b>的</b><b>权</b><b>利</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>1</b><b>.</b><b>3</b><b> </b><b>会</b><b>员</b><b>订</b><b>购</b
          ><b>服务</b><b>的</b><b>方式</b><b>仅</b><b>限</b><b>于</b><b>本</b
          ><b>公司</b><b>官</b><b>方</b><b>网</b><b>站</b><b>或</b><b>移</b
          ><b>动</b><b>应</b><b>用</b><b>程序</b><b>提</b><b>供</b><b>的</b
          ><b>方式</b><b>进行</b><b>订</b><b>购</b><b>，</b><b>且</b><b>必</b
          ><b>须</b><b>支付</b><b>相</b><b>应</b><b>的</b><b>费</b><b>用</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>二</b><b>、</b><b>会</b><b>员</b><b>资</b><b>格</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>2</b><b>.</b><b>1</b><b> </b><b>会</b><b>员</b><b>必</b><b>须</b
          ><b>为</b><b>年</b><b>满</b><b>18</b><b>周</b><b>岁</b><b>的</b
          ><b>自</b><b>然</b><b>人</b><b>，</b><b>具</b><b>有</b><b>完</b
          ><b>全</b><b>民</b><b>事</b><b>行</b><b>为</b><b>能</b><b>力</b
          ><b>，并</b><b>遵</b><b>守</b><b>本</b><b>协</b><b>议</b><b>的</b
          ><b>所有</b><b>条</b><b>款</b><b>和</b><b>条件</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>2</b><b>.</b><b>2</b><b> </b><b>会</b><b>员</b><b>应</b><b>当</b
          ><b>按</b><b>照</b><b>本</b><b>公司</b><b>要</b><b>求</b><b>提</b
          ><b>供</b><b>个</b><b>人</b><b>真</b><b>实</b><b>、</b><b>准</b
          ><b>确</b><b>、</b><b>完</b><b>整</b><b>的</b><b>信息</b><b>，并</b
          ><b>有</b><b>义</b><b>务</b><b>及</b><b>时</b><b>更新</b><b>个</b
          ><b>人</b><b>信息</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>2</b><b>.</b><b>3</b><b> </b><b>本</b><b>公司</b><b>有</b><b>权</b
          ><b>根</b><b>据</b><b>自</b><b>身</b><b>业</b><b>务</b><b>需要</b
          ><b>和</b><b>技</b><b>术</b><b>水</b><b>平</b><b>要</b><b>求</b
          ><b>对</b><b>会</b><b>员</b><b>资</b><b>格</b><b>进行</b><b>审核</b
          ><b>，</b><b>如</b><b>发</b><b>现</b><b>会</b><b>员</b><b>未</b
          ><b>能</b><b>符</b><b>合</b><b>资</b><b>格</b><b>要</b><b>求</b
          ><b>，</b><b>本</b><b>公司</b><b>有</b><b>权</b><b>拒</b><b>绝</b
          ><b>提</b><b>供</b><b>相</b><b>应</b><b>订</b><b>购</b><b>服务</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>三</b><b>、</b><b>费</b><b>用</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>3</b><b>.</b><b>1</b><b> </b><b>会</b><b>员</b><b>订</b><b>购</b
          ><b>服务</b><b>所</b><b>需</b><b>支付</b><b>的</b><b>费</b><b>用</b
          ><b>以</b><b>本</b><b>公司</b><b>官</b><b>方</b><b>网</b><b>站</b
          ><b>或</b><b>移</b><b>动</b><b>应</b><b>用</b><b>程序</b><b>公</b
          ><b>示</b><b>的</b><b>价格</b><b>为</b><b>准</b><b>，</b><b>本</b
          ><b>公司</b><b>保</b><b>留</b><b>单</b><b>方</b><b>面</b><b>调</b
          ><b>整</b><b>价格</b><b>的</b><b>权</b><b>利</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>3</b><b>.</b><b>2</b><b> </b><b>会</b><b>员</b><b>应</b><b>当</b
          ><b>按</b><b>照</b><b>订单</b><b>的</b><b>要</b><b>求</b><b>，</b
          ><b>按</b><b>时</b><b>支付</b><b>相关</b><b>费</b><b>用</b><b>，</b
          ><b>如</b><b>未</b><b>按</b><b>时</b><b>支付</b><b>相关</b><b>费</b
          ><b>用</b><b>，</b><b>本</b><b>公司</b><b>有</b><b>权</b><b>中</b
          ><b>止</b><b>或</b><b>终</b><b>止</b><b>会</b><b>员</b><b>的</b
          ><b>使用</b><b>权限</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>3</b><b>.</b><b>3</b><b> 如</b><b>会</b><b>员</b><b>因</b><b>订</b
          ><b>购</b><b>服务</b><b>产</b><b>生</b><b>的</b><b>费</b><b>用</b
          ><b>未</b><b>能</b><b>及</b><b>时</b><b>支付</b><b>，</b><b>本</b
          ><b>公司</b><b>有</b><b>权</b><b>依</b><b>法</b><b>采</b><b>取</b
          ><b>法</b><b>律</b><b>手</b><b>段</b><b>进行</b><b>追</b><b>缴</b
          ><b>，并</b><b>有</b><b>权</b><b>暂</b><b>停</b><b>或</b><b>终</b
          ><b>止</b><b>该</b><b>会</b><b>员</b><b>在</b><b>本</b><b>公司</b
          ><b>的</b><b>所有</b><b>服务</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>四</b><b>、</b><b>知</b><b>识</b><b>产</b><b>权</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>4</b><b>.</b><b>1</b><b> </b><b>本</b><b>公司</b><b>在</b><b>订</b
          ><b>购</b><b>服务</b><b>中</b><b>提</b><b>供</b><b>的</b><b>所有</b
          ><b>软</b><b>件</b><b>、</b><b>技</b><b>术</b><b>、</b><b>资</b
          ><b>料</b><b>、</b><b>商</b><b>标</b><b>等</b><b>所有</b><b>权</b
          ><b>、</b><b>知</b><b>识</b><b>产</b><b>权</b><b>均</b><b>归</b
          ><b>本</b><b>公司</b><b>所有</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>4</b><b>.</b><b>2</b><b> </b><b>未</b><b>经</b><b>本</b><b>公司</b
          ><b>事</b><b>先</b><b>书</b><b>面</b><b>许</b><b>可</b><b>，</b
          ><b>会</b><b>员</b><b>不</b><b>得</b><b>或</b><b>允</b><b>许</b
          ><b>任</b><b>何</b><b>其他</b><b>人</b><b>使用</b><b>、</b><b>复</b
          ><b>制</b><b>、</b><b>修改</b><b>、</b><b>翻</b><b>译</b><b>、</b
          ><b>分</b><b>发</b><b>、</b><b>开</b><b>发</b><b>、</b><b>出</b
          ><b>售</b><b>、</b><b>转</b><b>让</b><b>或</b><b>许</b><b>可</b
          ><b>上</b><b>述</b><b>知</b><b>识</b><b>产</b><b>权</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>五</b><b>、</b><b>服务</b><b>的</b><b>中</b><b>断</b><b>或</b
          ><b>终</b><b>止</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>5</b><b>.</b><b>1</b><b> 因</b><b>故</b><b>障</b><b>、</b><b>技</b
          ><b>术</b><b>调</b><b>整</b><b>、</b><b>安</b><b>全</b><b>问题</b
          ><b>等</b><b>因</b><b>素</b><b>，</b><b>导</b><b>致</b><b>会</b
          ><b>员</b><b>无</b><b>法</b><b>使用</b><b>订</b><b>购</b><b>服务</b
          ><b>的</b><b>，</b><b>本</b><b>公司</b><b>将</b><b>尽</b><b>快</b
          ><b>修</b><b>复</b><b>，</b><b>恢</b><b>复</b><b>服务</b><b>，并</b
          ><b>承</b><b>担</b><b>由</b><b>此</b><b>产</b><b>生</b><b>的</b
          ><b>必</b><b>要</b><b>费</b><b>用</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>5</b><b>.</b><b>2</b><b> 在</b><b>以下</b><b>情</b><b>况</b
          ><b>下</b><b>，</b><b>本</b><b>公司</b><b>有</b><b>权</b><b>单</b
          ><b>方</b><b>面</b><b>中</b><b>断</b><b>或</b><b>终</b><b>止</b
          ><b>会</b><b>员</b><b>订</b><b>购</b><b>服务</b
          ><b
            >：
            <p style=""></p>
            <p style=""></p></b
          ><b>（</b><b>1</b><b>）</b><b>会</b><b>员</b><b>违</b><b>反</b
          ><b>本</b><b>协</b><b>议</b><b>相关</b><b>规</b><b>定</b><b>，</b
          ><b>包</b><b>括</b><b>但</b><b>不</b><b>限</b><b>于</b><b>未</b
          ><b>按</b><b>时</b><b>支付</b><b>费</b><b>用</b><b>、</b><b>提</b
          ><b>供</b><b>虚</b><b>假</b><b>信息</b><b>、</b><b>传</b><b>播</b
          ><b>违</b><b>法</b><b>信息</b><b>或</b><b>侵</b><b>害</b><b>他</b
          ><b>人</b><b>权</b><b>益</b><b>的</b><b>行</b><b>为</b><b>等</b
          ><b>；</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>（</b><b>2</b><b>）</b><b>会</b><b>员</b><b>违</b><b>反</b
          ><b>中</b><b>华</b><b>人</b><b>民</b><b>共</b><b>和</b><b>国</b
          ><b>法</b><b>律</b><b>法</b><b>规</b><b>的</b><b>相关</b><b>规</b
          ><b>定</b><b>，</b><b>从</b><b>事</b><b>违</b><b>法</b><b>犯</b
          ><b>罪</b><b>活</b><b>动</b><b>的</b><b>行</b><b>为</b><b>；</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>（</b><b>3</b><b>）</b><b>在</b><b>本</b><b>公司</b><b>业</b
          ><b>务</b><b>调</b><b>整</b><b>、</b><b>技</b><b>术</b><b>升</b
          ><b>级</b><b>、</b><b>法</b><b>律</b><b>法</b><b>规</b><b>变</b
          ><b>化</b><b>等</b><b>原</b><b>因</b><b>下</b><b>，</b><b>本</b
          ><b>公司</b><b>无</b><b>法</b><b>继</b><b>续</b><b>提</b><b>供</b
          ><b>相关</b><b>订</b><b>购</b><b>服务</b><b>的</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>六</b><b>、</b><b>免</b><b>责</b><b>条</b><b>款</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>6</b><b>.</b><b>1</b><b> </b><b>本</b><b>公司</b><b>不</b><b>对</b
          ><b>会</b><b>员</b><b>因</b><b>订</b><b>购</b><b>服务</b><b>而</b
          ><b>造</b><b>成</b><b>的</b><b>损</b><b>失</b><b>承</b><b>担</b
          ><b>任</b><b>何</b><b>责</b><b>任</b><b>，</b><b>包</b><b>括</b
          ><b>但</b><b>不</b><b>限</b><b>于</b><b>直</b><b>接</b><b>或</b
          ><b>间</b><b>接</b><b>的</b><b>经</b><b>济</b><b>损</b><b>失</b
          ><b>、</b><b>商</b><b>誉</b><b>损</b><b>失</b><b>、</b><b>数据</b
          ><b>损</b><b>失</b><b>等</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>6</b><b>.</b><b>2</b><b> </b><b>会</b><b>员</b><b>因</b><b>个</b
          ><b>人</b><b>行</b><b>为</b><b>而</b><b>导</b><b>致</b><b>的</b
          ><b>任</b><b>何</b><b>法</b><b>律</b><b>责</b><b>任</b><b>，</b
          ><b>本</b><b>公司</b><b>不</b><b>承</b><b>担</b><b>任</b><b>何</b
          ><b>责</b><b>任</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>七</b><b>、</b><b>争</b><b>议</b><b>解</b><b>决</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>7</b><b>.</b><b>1</b><b> </b><b>本</b><b>协</b><b>议</b><b>的</b
          ><b>解</b><b>释</b><b>和</b><b>适</b><b>用</b><b>，</b><b>以</b
          ><b>及</b><b>会</b><b>员</b><b>与</b><b>本</b><b>公司</b><b>之</b
          ><b>间</b><b>的</b><b>争</b><b>议</b><b>解</b><b>决</b><b>，</b
          ><b>均</b><b>适</b><b>用</b><b>中</b><b>华</b><b>人</b><b>民</b
          ><b>共</b><b>和</b><b>国</b><b>法</b><b>律</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>7</b><b>.</b><b>2</b><b> 如</b><b>会</b><b>员</b><b>与</b><b>本</b
          ><b>公司</b><b>之</b><b>间</b><b>发</b><b>生</b><b>争</b><b>议</b
          ><b>，</b><b>应</b><b>当</b><b>通过</b><b>友</b><b>好</b><b>协</b
          ><b>商</b><b>解</b><b>决</b><b>；</b><b>协</b><b>商</b><b>不</b
          ><b>成</b><b>时</b><b>，</b><b>任</b><b>何</b><b>一</b><b>方</b
          ><b>均</b><b>有</b><b>权</b><b>向</b><b>本</b><b>公司</b><b>所</b
          ><b>在</b><b>地</b><b>有</b><b>管</b><b>辖</b><b>权</b><b>的</b
          ><b>人</b><b>民</b><b>法</b><b>院</b><b>提</b><b>起</b><b>诉</b
          ><b>讼</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>八</b><b>、</b><b>其他</b
          ><b
            ><p style=""></p>
            <p style=""></p></b
          ><b>8</b><b>.</b><b>1</b><b> </b><b>本</b><b>协</b><b>议</b><b>自</b
          ><b>会</b><b>员</b><b>订</b><b>购</b><b>服务</b><b>的</b><b>日期</b
          ><b>生</b><b>效</b><b>，</b><b>直</b><b>到</b><b>会</b><b>员</b
          ><b>行</b><b>为</b><b>符</b><b>合</b><b>本</b><b>协</b><b>议</b
          ><b>所有</b><b>规</b><b>定</b><b>且</b><b>本</b><b>公司</b><b>终</b
          ><b>止</b><b>相关</b><b>服务</b><b>的</b><b>期</b><b>间</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>8</b><b>.</b><b>2</b><b> </b><b>本</b><b>协</b><b>议</b><b>未</b
          ><b>涉</b><b>及</b><b>的</b><b>其他</b><b>事</b><b>项</b><b>，</b
          ><b>均</b><b>应</b><b>按</b><b>照</b><b>本</b><b>公司</b><b>官</b
          ><b>方</b><b>网</b><b>站</b><b>或</b><b>移</b><b>动</b><b>应</b
          ><b>用</b><b>程序</b><b>的</b><b>相关</b><b>规</b><b>定</b><b>执行</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>8</b><b>.</b><b>3</b><b> </b><b>本</b><b>协</b><b>议</b><b>条</b
          ><b>款</b><b>存在</b><b>任</b><b>何</b><b>与</b><b>中</b><b>华</b
          ><b>人</b><b>民</b><b>共</b><b>和</b><b>国</b><b>法</b><b>律</b
          ><b>法</b><b>规</b><b>和</b><b>相关</b><b>政</b><b>策</b><b>不</b
          ><b>符</b><b>合</b><b>的</b><b>情</b><b>况</b><b>，</b><b>均</b
          ><b>无</b><b>效</b><b>，</b><b>但</b><b>不</b><b>影</b><b>响</b
          ><b>其他</b><b>条</b><b>款</b><b>的</b><b>效</b><b>力</b
          ><b
            >。
            <p style=""></p>
            <p style=""></p></b
          ><b>以上</b><b>为</b><b>本</b><b>公司</b><b>订</b><b>阅</b><b>会</b
          ><b>员</b><b>协</b><b>议</b><b>，</b><b>会</b><b>员</b><b>在</b
          ><b>完成</b><b>订</b><b>购</b><b>服务</b><b>前</b><b>应</b><b>当</b
          ><b>先</b><b>仔</b><b>细</b><b>阅</b><b>读</b><b>并</b><b>了</b
          ><b>解</b><b>本</b><b>协</b><b>议</b><b>的</b><b>所有</b><b>条</b
          ><b>款</b><b>和</b><b>条件</b><b>。</b></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy-page",
  data() {
    return {
      key: "",
    };
  },
  onLoad() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      // this.$u.api.yszc().then((res) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.app-body {
  margin: 0 auto;
  max-width: 1256px;
  height: 100%;
}
.privacy-page {
  background-color: #ffffff;
  padding: 20px;
  overflow-x: hidden;
  a {
    outline: none;
    color: #3a3a3a;
  }
  .user-page-title {
    font-family: "Space Grotesk";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin: 28px 0;
  }
  .user-page-body {
    font-family: "Space Grotesk";
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    p {
      margin-bottom: 12px;
    }
  }
}
</style>
