import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);
const appName = "超智云 AI";
const routes = [

  {
    path: "/agreement",
    name: "agreement",
    component: require("@/views/agreement.vue").default,
  },
  {
    path: "",
    component: require("@/layout/index.vue").default,
    children: [
      {
        path: "/",
        name: "home",
        component: require("@/views/home.vue").default,
      },
      {
        path: "/guide",
        name: "guide",
        component: require("@/views/guide.vue").default,
      },
      {
        path: "/disclaimer",
        name: "disclaimer",
        component: require("@/views/disclaimer.vue").default,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  store.dispatch("GetInfo");
  document.title = to.meta?.title || appName;

  next();
});
export default router;
