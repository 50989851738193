<template>
  <div class="app-aside flex-0 flex flex-column">
    <div
      class="aside-user-info flex-0 flex flex-column flex-center"
      @click="onAuth"
    >
      <div class="aside-user-avatar">
        <el-image :src="user.avatar || defaultAvatar" fit="cover"></el-image>
      </div>
      <div v-if="!hasLogin" class="aside-user-tip">点击登录</div>
      <div v-else class="aside-user-tip">
        {{ user.nickname || user.username }}
      </div>
    </div>
    <div class="aside-menu-options flex-0" @click="jumpAdd">
      <div class="aside-menu-option aside-menu-add">
        <i class="aside-menu-icon el-icon-plus"></i>
        新建聊天
      </div>
    </div>
    <div class="flex-1 ov-auto">
      <div
        :key="`chatCount-${chatCount}`"
        class="aside-menu-list aside-menu-options"
      >
        <div
          v-for="chatId in chatList"
          :key="chatId"
          :class="{
            'aside-menu-chat flex aside-menu-option ': true,
            'aside-menu-option-active': chatActive === chatId,
          }"
          @click="jumpActive(chatId)"
        >
          <i class="aside-menu-icon el-icon-chat-dot-square flex-0"></i>
          <span class="line-one flex-1">
            <span
              :key="`old-${chats[chats.length - 1]?.oldText}`"
              v-html="filterChat(chatId)"
            ></span>
          </span>
          <i class="el-icon-delete flex-0" @click="removeChat(chatId)"></i>
        </div>
      </div>
    </div>
    <div class="aside-menu-options flex-0">
      <template v-for="option in options">
        <div
          v-if="!option.hide || (option.hide && hasLogin)"
          :key="option.name"
          :class="{
            'aside-menu-option': true,
            'aside-menu-option-active': $route.path === option.path,
          }"
          @click="option.callback()"
        >
          <i :class="['aside-menu-icon', option.icon]"></i>
          {{ option.name }}
        </div>
      </template>
    </div>
    <vip-dialog v-if="hasLogin" v-model="show.member"></vip-dialog>
    <el-dialog
      :show-close="false"
      :visible="show.bindPhone"
      class="cus-bind-phone"
      width="950px"
    >
      <div class="bindPhone_container flex flex-row-around" data-v-4bc4c720="">
        <div class="left_con">
          <p class="left_title">开启与人工智能的全新互动方式</p>
          <div class="flex">
            <div class="mr60">
              <p>点燃创意的火花</p>
              <p class="mt20">无界连接，无尽乐趣</p>
              <p class="mt20">发问不必犹豫</p>
            </div>
            <div>
              <img
                alt="logo"
                src="@/assets/images/mob_dia.png"
                style="user-select: none"
              />
            </div>
          </div>
        </div>
        <div class="box_right">
          <p class="bindPhone_title">绑定手机号，会员不丢失</p>
          <el-form>
            <div class="input_con flex mt20">
              <el-form-item>
                <el-input
                  auto-complete="off"
                  maxlength="11"
                  placeholder="请输入手机号"
                  size="large"
                  style="width: 302px"
                  tabindex="0"
                >
                  <template #prepend>+86</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="input_con flex">
              <div class="flex flex_1">
                <el-form-item>
                  <el-input
                    auto-complete="off"
                    maxlength="6"
                    placeholder="请输入短信验证码"
                    size="large"
                    style="width: 302px"
                    tabindex="0"
                  >
                    <template #suffix>
                      <el-button type="text"> 获取验证码 </el-button>
                    </template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
            <div class="txal">
              <el-button disabled style="width: 100%" type="primary">
                绑定
              </el-button>
            </div>
          </el-form>
        </div>
        <!---->
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="show.auth"
      append-to-body
      custom-class="base-dialog auth-dialog"
      modal-append-to-body
      width="400px"
    >
      <div class="flex flex-center flex-column">
        <div class="auth-dialog-title">关注微信公众号，欢迎使用</div>
        <div v-loading="codeLoading" class="auth-dialog-code">
          <el-image v-if="codeInfo.qrcode" :src="codeInfo.qrcode"></el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { guid } from "@/utils/common";
import { getToken, setToken } from "@/utils/auth";
import { getLoginQrCode, queryQrCodeStatus } from "@/api";
import VipDialog from "@/layout/components/vip-dialog.vue";

export default {
  name: "app-aside",
  components: { VipDialog },
  data() {
    return {
      defaultAvatar: require("@/assets/images/default-avatar.png"),
      show: {
        auth: false,
        bindPhone: false,
        contact: false,
        member: false,
        order: false,
      },
      codeInfo: {
        qrcode: "",
        request_id: "",
      },
      codeLoading: true,
      codeState: false,
      timer: null,
      options: [
        {
          name: "清除所有会话",
          icon: "el-icon-delete",
          callback: () => {
            this.clearAllChat();
            this.jump("/");
          },
        },
        // {
        //   name: "联系客服",
        //   icon: "el-icon-phone-outline",
        //   callback: () => {
        //     this.show.contact = true;
        //   },
        // },
        {
          name: "升级会员",
          hide: true,
          icon: "el-icon-shopping-cart-2",
          callback: () => {
            this.show.member = true;
          },
        },
        // {
        //   name: "订单记录",
        //   hide: true,
        //   icon: "el-icon-time",
        //   callback: () => {
        //     this.show.order = true;
        //   },
        // },
        {
          name: "退出登录",
          icon: "el-icon-switch-button",
          hide: true,
          callback: () => {
            this.clearUser();
          },
        },
        {
          name: "操作指南",
          icon: "el-icon-help",
          path: "/guide",
          callback: () => {
            this.setChatActive("");
            this.jump("/guide");
          },
        },
        {
          name: "免责声明",
          icon: "el-icon-warning-outline",
          path: "/disclaimer",
          callback: () => {
            this.setChatActive("");
            this.jump("/disclaimer");
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      chatList: (state) => state.chat,
      chats: (state) => state.chats,
      chatActive: (state) => state.chatActive,
      chatCount: (state) => state.chatCount,
    }),
    hasLogin() {
      return !!this.user?.token;
    },
  },
  methods: {
    ...mapMutations([
      "clearAllChat",
      "addChat",
      "setChatActive",
      "clearUser",
      "removeChat",
    ]),
    ...mapActions(["GetInfo"]),
    filterChat(chatId) {
      const chats = this?.chats[chatId] || [];
      return chats[chats.length - 1]?.oldText || "新建对话";
    },
    jumpActive(chatId) {
      this.jump('/')
      this.setChatActive(chatId);
    },
    jumpAdd() {
      this.addChat({
        chatId: guid(),
        callback: () => {
          this.$forceUpdate();
        },
      });
      this.jump("/");
    },
    // 未登录则打开弹窗
    onAuth() {
      if (!getToken()) {
        this.codeLoading = true;
        getLoginQrCode()
          .then((res) => {
            this.codeInfo = res.data;
            this.codeLoading = false;
            this.getWxCodeResult();
            this.codeState = true;
          })
          .catch((err) => {
            this.codeLoading = false;
            console.log(err);
          });
        this.show.auth = true;
      }
    },

    // 获取扫码结果
    getWxCodeResult() {
      this.timer = setInterval(() => {
        if (!this.show.auth) {
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        queryQrCodeStatus({ request_id: this.codeInfo.request_id }).then(
          (res) => {
            if (res.data) {
              clearInterval(this.timer);
              this.timer = null;
              setToken(res.data.token);
              this.$message.success("登录成功");
              this.show.auth = false;
              this.GetInfo();
            }
          }
        );
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.ov-auto {
  overflow: auto;
  min-height: 120px;
}
.app-aside {
  height: 100vh;
  overflow: auto;
  width: 240px;
  min-width: 240px;
  font-size: 14px;
  background-color: #232429;
  color: rgb(249, 249, 249);
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  padding-bottom: 10px;
  .aside-user-info {
    padding: 15px 0;
    cursor: pointer;
    .aside-user-avatar {
      margin-top: 5px;
      width: 44px;
      height: 44px;
      overflow: hidden;
      border-radius: 50%;
    }
    .aside-user-tip {
      line-height: 40px;
      font-size: 16px;
      color: #efefef;
    }
  }
  .aside-menu-list {
    overflow: auto;
    padding: 8px 20px;
    .el-icon-delete {
      padding: 13px;
      font-size: 14px;
      display: none;
      transition: 0.2s all ease-in;
      color: #efefef;
      &:hover {
        color: #ffffff;
      }
    }
    .aside-menu-chat {
      padding-right: 0 !important;
      &:hover {
        .el-icon-delete {
          display: block;
        }
      }
    }
    .aside-menu-option {
      border-radius: 8px;
      margin-bottom: 10px;
    }
  }
  .aside-menu-options {
    .aside-menu-option {
      height: 46px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      transition: 0.2s all ease-in;
      &:hover {
        background-color: #3e3f43;
      }
      &-active {
        background-color: #46474b;
      }
      .aside-menu-icon {
        margin-right: 12px;
      }
    }
  }
  .aside-menu-icon {
    font-size: 18px;
  }
}
</style>
<style lang="scss">
.auth-dialog {
  .auth-dialog-title {
    color: #000000;
    padding: 15px 0;
    margin-bottom: 8px;
    font-size: 20px;
  }
  .auth-dialog-code {
    width: 160px;
    height: 160px;
    margin: 8px 16px 16px;
    box-sizing: border-box;
  }
}
.cus-bind-phone {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    background-color: #ecf2fe;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mr80 {
    margin-right: 80px;
  }
  .mr60 {
    margin-right: 60px;
  }
  .bindPhone_container {
    padding: 45px 0;
  }
  .left_con {
    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .left_title {
    font-weight: 700;
    color: #333;
    font-size: 18px;
    margin-bottom: 30px;
    transform: translateY(-10px);
  }
  .bindPhone_title {
    text-align: left;
    font-weight: 700;
    color: #333;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .box_right {
    background: #fff;
    width: 350px;
    height: 295px;
    padding: 10px 24px 24px 24px;
  }
}
</style>
