import axios from "axios-ext";
import { Notification, Message } from "element-ui";
import { getToken, removeToken } from "@/utils/auth";
import errorCode from "@/utils/errorCode";
import { tansParams } from "@/utils/ruoyi";
import cache from "@/utils/cache";
import store from "@/store";
import { formatParams } from "@/utils/common";
import { EventSourcePolyfill } from "@/utils/eventsourece";

// 是否显示重新登录
export let isRelogin = { show: false };

// 创建axios实例
axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // 超时
  timeout: 10000,
});
const { $service } = axios;
// request拦截器
$service.interceptors.request.use(
  (config) => {
    config.url = process.env.VUE_APP_BASE_API + config.url;
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
    config.headers["Content-Type"] = "application/json;charset=utf-8";

    if (getToken() && !isToken) {
      config.headers["token"] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (
      !isRepeatSubmit &&
      (config.method === "post" || config.method === "put")
    ) {
      const requestObj = {
        url: config.url,
        data:
          typeof config.data === "object"
            ? JSON.stringify(config.data)
            : config.data,
        time: new Date().getTime(),
      };
      const sessionObj = cache.session.getJSON("sessionObj");
      if (
        sessionObj === undefined ||
        sessionObj === null ||
        sessionObj === ""
      ) {
        cache.session.setJSON("sessionObj", requestObj);
      } else {
        const s_url = sessionObj.url; // 请求地址
        const s_data = sessionObj.data; // 请求数据
        const s_time = sessionObj.time; // 请求时间
        const interval = 800; // 间隔时间(ms)，小于此时间视为重复提交
        if (
          s_data === requestObj.data &&
          requestObj.time - s_time < interval &&
          s_url === requestObj.url
        ) {
          const message = "数据正在处理，请勿重复提交";
          console.warn(`[${s_url}]: ` + message);
          // return Promise.reject(new Error(message));
        } else {
          cache.session.setJSON("sessionObj", requestObj);
        }
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
$service.interceptors.response.use(
  (res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer"
    ) {
      return res.data;
    }
    if (code === 401) {
      return Promise.reject("无效的会话，或者会话已过期，请重新登录。");
    } else if (code === 500) {
      Message({ message: msg, type: "error" });
      return Promise.reject(new Error(msg));
    } else if (code === 601) {
      Message({ message: msg, type: "warning" });
      return Promise.reject("error");
    } else if (![200, 1].includes(code)) {
      Notification.error({ title: msg });
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    let { message, request } = error;

    if ([401, 403].includes(request.status)) {
      removeToken();
      Message({
        message: "登录信息已失效，请重新登录",
        type: "warning",
        duration: 2 * 1000,
      });
      store.commit("clearUser");
      return Promise.reject(error);
    }
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({ message: message, type: "error", duration: 5 * 1000 });
    return Promise.reject(error);
  }
);

const verifyUrl = (value) => {
  return /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/.test(value);
};
export const stream = (url, data = {}, callback) => {
  url = verifyUrl(url)
    ? url
    : process.env.VUE_APP_BASE_API + (url.indexOf("/") == 0 ? url : "/" + url);
  if (data) {
    url += formatParams(data);
  }
  const eventSource = new EventSourcePolyfill(url, {
    headers: {
      token: getToken(),
    },
  });
  // /*
  //  * open：订阅成功（和后端连接成功）
  //  */
  eventSource.addEventListener("open", function (msg) {
    callback && callback({ type: "open", msg });
  });
  /*
   * message：后端返回信息，格式可以和后端协商
   */
  eventSource.addEventListener("message", function (event) {
    let data = JSON.parse(event.data);
    let content = data.content
      .replaceAll(/\s+/g,'&nbsp;')
      .replaceAll(/[\t]/g, `&nbsp;&nbsp;`)
      .replaceAll(/[\r\n]/g, `<p style="margin: 0 0 2px;"></p>`)
      .replaceAll(/[\`]/g, `<p style="margin: 0 0 2px; "></p>`)
    callback && callback({ msg: content, data: data.content });
  });
  /*
   * error：错误（可能是断开，可能是后端返回的信息）
   */
  eventSource.addEventListener("error", function (err) {
    if (
      [401, 403].includes(err.status) ||
      [401, 403].includes(err.statusCode)
    ) {
      store.commit("clearUser");
      let reqErr = {
        msg: "",
        type: "end",
        reqErr: true,
        auth: true,
        err: { errMsg: "请登录后进行对话" },
      };
      callback && callback(reqErr);
      eventSource.close();
      return;
    }
    let reqErr = { msg: "", type: "end" };
    if (err.error?.reqErr) {
      err.errMsg = err.error.msg;
      delete err.error;
      reqErr.reqErr = true;
    }
    reqErr.err = err;
    callback && callback(reqErr);

    // 需要关闭了
    eventSource.close();
  });
};

export default axios;
