<template>
  <div :class="{ 'home-page': true, 'home-page-hide': !nowChat.length }">
    <div class="flex-0">
      <div
        :style="{
          paddingTop: nowChat.length ? '10px' : '80px',
          marginBottom: nowChat.length ? '0px' : '60px',
        }"
        class="app-title"
      >
        超智云 AI
      </div>
      <div v-if="!nowChat.length" class="app-types flex flex-row-center">
        <div v-for="type in typeList" :key="type.name" class="app-type">
          <div class="app-type-info flex flex-column flex-center">
            <i :class="['app-type-icon', type.icon]"></i>
            <div class="line-one">{{ type.name }}</div>
          </div>
          <div
            v-for="(child, childIndex) in type.child"
            :key="`chat-${childIndex}`"
            class="app-type-item flex flex-column"
            @click="
              submitChat(
                {
                  text: child,
                  oldText: child,
                  format: true,
                  mine: true,
                },
                nowChat.length
              )
            "
          >
            <div class="app-type-item-txt flex-1 line-two">
              {{ child }}
            </div>
            <div class="flex-0 flex flex-row-right">
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="auth-qrcode">-->
    <!--      <el-image-->
    <!--        ref="authQrCode"-->
    <!--        :preview-src-list="[this.codeInfo.qrcode]"-->
    <!--      ></el-image>-->
    <!--    </div>-->
    <el-image-viewer
      v-if="show.preview"
      :on-close="closeImage"
      :url-list="[this.show.previewImage]"
    />

    <!-- nowChat列表数据直接压入组件 -->
    <JwChat-index
      v-model="inputMsg"
      :config="config"
      :show-right-box="false"
      :taleList="nowChat"
      :toolConfig="tool"
      placeholder="回车或点击发送即发送消息给 AI"
      scrollType="scroll"
      @clickTalk="bindEnter"
      @enter="bindEnter"
    >
      <div slot="downBtn" class="arrow-down-icon">
        <el-icon name="arrow-down"></el-icon>
      </div>

      <div slot="enter" class="enter-body flex flex-col-center w-full">
        <div class="flex flex-col-center flex-1">
          <div class="enter-content w-full">
            {{ inputMsg }}
          </div>
          <textarea
            v-model="inputMsg"
            auto-height
            class="t_area"
            disable-default-padding
            placeholder="回车或点击发送即发送消息给 AI"
          />
        </div>
        <i
          slot="enterBtn"
          class="el-icon-s-promotion flex-0"
          @click="bindEnter"
        ></i>
      </div>
    </JwChat-index>
    <vip-dialog v-model="show.member"></vip-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { aiResult, getLoginQrCode, queryQrCodeStatus } from "@/api";
import { getToken, setToken } from "@/utils/auth";
import { guid } from "@/utils/common";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
// 引入代码高亮样式
import "highlight.js/styles/atom-one-dark.css";
import VipDialog from "@/layout/components/vip-dialog.vue";
export default {
  name: "home-page",
  components: { VipDialog, ElImageViewer },
  data() {
    return {
      show: {
        preview: false,
        previewImage: "",
        member: false
      },
      previewImage: {},
      img: "https://oss.zwk999.com/chatgpt/static/img/th.png",
      typeList: [
        {
          icon: "el-icon-attract",
          name: "AI 创作",
          child: [
            "写一首赞美祖国的诗，要求50字以上",
            "写一个系统升级的公告",
            "写一份周报，50字以上",
          ],
        },
        {
          icon: "el-icon-magic-stick",
          name: "有趣的问题",
          child: [
            "什么情况下，老鼠可以吃掉大象",
            "Ai 会替代人类工作吗？",
            "为什么有三八妇女节，却没有男人节？",
          ],
        },
        {
          icon: "el-icon-s-opportunity",
          name: "AI百科",
          child: [
            "中国只有唯一一位女皇帝，你觉得这是为什么？",
            "糖醋鲤鱼的做法",
            "唐僧的老婆是谁",
          ],
        },
      ],
      inputMsg: "",
      tool: {
        showEmoji: false, // 是否显示表情图标
      },
      config: {
        name: "超智云 AI",
      },
      codeInfo: {
        qrcode: "",
        request_id: "",
      },
      timer: null,
    };
  },
  computed: {
    ...mapState(["chat", "chats", "chatActive", "user", "chatCount"]),
    ...mapGetters(["nowChat"]),
  },
  methods: {
    ...mapActions(["GetInfo"]),
    ...mapMutations(["pushChat", "editChat"]),
    closeImage() {
      this.show.preview = false;
    },
    addChat(
      { text, mine = true, state = true, format = false, oldText, button = "" },
      callback
    ) {
      this.pushChat({
        text: {
          text: format ? `<p>${text}</p>${button}` : text,
        },
        mine,
        oldText,
        state,
        img: mine
          ? this.user.avatar || require("@/assets/images/default-avatar.png")
          : this.img,
        callback,
      });
      this.$forceUpdate();
    },
    submitChat(data, nowIndex) {
      this.inputMsg = "";
      const oldAi = this.nowChat[nowIndex - 1]?.oldText;
      this.addChat(data, (chatActive) => {
        this.$forceUpdate();
        let params = { input: data.text };
        if (oldAi) {
          params.system_say = oldAi;
        }
        aiResult(params, (val) => {
          // 植入数据
          if (typeof val.data === "string") {
            val.msg = val.msg ? `<p>${val.msg}</p>` : "";
            this.editChat({
              chatActive,
              index: nowIndex + 1,
              text: val.msg,
              oldText: val.data,
              status: false,
              callback: () => {
                this.$forceUpdate();
              },
            });
            this.$forceUpdate();
          }
          if (!val.reqErr && val.type === "end") {
            setTimeout(() => {
              this.editChat({
                chatActive,
                index: nowIndex + 1,
                text: "",
                button: val.button,
                oldText: "",
                status: true,
                callback: () => {
                  this.$forceUpdate();
                },
              });
            });
          }
          // 接口报错
          if (val.reqErr) {
            if (!val.auth) {
              let uuid = guid(8);
              this.addChat(
                {
                  text: val?.err?.errMsg,
                  mine: false,
                  format: true,
                  oldText: val?.err?.errMsg,
                  button:
                    val?.err?.button ||
                    (val?.err?.errMsg.includes("会员")
                      ? `<p><button style="margin-top: 5px;" id="pay-vip-btn-${uuid}" class=" el-button el-button--primary el-button--mini">充值会员</button></p>`
                      : ""),
                },
                () => {
                  this.$forceUpdate();
                  this.$nextTick(() => {
                    document
                      .getElementById("pay-vip-btn-" + uuid)
                      .addEventListener("click", () => {
                        this.show.member = true
                      });
                  });
                }
              );
            }

            if (val.auth) {
              this.onAuth((code) => {
                let uuid = guid(8);
                this.previewImage[uuid] = code;
                this.addChat(
                  {
                    text: val?.err?.errMsg,
                    mine: false,
                    format: true,
                    oldText: val?.err?.errMsg,
                    button: `<p style="margin: 0;height: 122px;" class="flex flex-center">
                      <img src="${this.codeInfo.qrcode}" id="img-${uuid}" style="width: 120px; height: 120px;cursor:pointer;padding: 3px;"/>
                      </p>`,
                  },
                  () => {
                    this.$forceUpdate();
                    this.$nextTick(() => {
                      document
                        .getElementById("img-" + uuid)
                        .addEventListener("click", () => {
                          this.show.previewImage = [this.previewImage[uuid]];
                          this.show.preview = true;
                        });
                    });
                  }
                );
              });
            }
          }
        });
      });
    },

    // 未登录则打开弹窗
    onAuth(callback) {
      if (this.codeInfo.qrcode) {
        callback && callback(this.codeInfo.qrcode);
        return;
      }
      getLoginQrCode()
        .then((res) => {
          this.codeInfo = res.data;
          callback && callback(res.data.qrcode);
          this.getWxCodeResult();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取扫码结果
    getWxCodeResult() {
      this.timer = setInterval(() => {
        if (getToken()) {
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        queryQrCodeStatus({ request_id: this.codeInfo.request_id }).then(
          (res) => {
            if (res.data) {
              clearInterval(this.timer);
              this.timer = null;
              setToken(res.data.token);
              this.$message.success("登录成功");
              setTimeout(() => {
                this.codeInfo = this.$options.data().codeInfo;
                this.GetInfo();
              });
            }
          }
        );
      }, 1000);
    },
    bindEnter() {
      if (!this.inputMsg) {
        return;
      }
      this.submitChat(
        {
          text: this.inputMsg,
          oldText: this.inputMsg,
          format: true,
          mine: true,
        },
        this.nowChat.length
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.t_area {
  width: 100%;
  height: 100%;
  overflow-y: visible;
}
.enter-body {
  position: relative;
  width: 100%;
  margin: 5px 10px;
  textarea {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    outline: none;
    resize: none;
    border: 0;
    padding: 0;
    color: #333333;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
      Microsoft YaHei, Arial, sans-serif;
  }
}
.w-full {
  width: 100%;
}
.enter-content {
  visibility: hidden;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 28px;
  word-break: break-word;
  word-wrap: break-word;
  font-style: normal;
  min-height: 20px;
}
.home-page {
  height: 100%;
  min-width: 300px;
  color: #2c3e50;
  display: flex;
  flex-flow: column;
  .app-title {
    padding-top: 30px;
    font-weight: 600;
    margin-bottom: 60px;
    font-size: 36px;
    text-align: center;
    transition: 0.2s all ease-in-out;
  }
  .app-types {
    margin: 0 10px;
    .app-type {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
    .app-type-info {
      font-size: 18px;
      line-height: 24px;
      padding: 20px 0;
      .app-type-icon {
        font-size: 32px;
        margin-bottom: 10px;
      }
    }
    .app-type-item {
      padding: 10px;
      background-color: #00000009;
      margin-bottom: 10px;
      border-radius: 6px;
      max-width: 250px;
      cursor: pointer;
      transition: 0.2s all ease-in;
      &:hover {
        color: #ffffff;
        background-color: #3390ff;
      }
    }
    .app-type-item-txt {
      min-height: 40px;
    }
  }
  ::v-deep .ChatPage {
    box-shadow: initial;
    width: 100% !important;
    height: initial !important;
    flex: 1;
    .el-icon-s-promotion {
      color: #cbcad1;
      font-size: 28px;
      position: relative;
      padding: 12px;
      margin: -12px;
      cursor: pointer;
      transition: 0.2s all ease-in;
      &:hover {
        color: #333333;
      }
    }
    .web__msg {
      height: auto;
      border-radius: 12px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12), 0 0 12px rgba(0, 0, 0, 0.04);
      border: 1px solid #e6e6e6;
      display: flex;
      flex-flow: row;
    }
    .web__msg-input {
      min-height: 20px;
    }
    .web__msg-menu {
      display: flex;
      align-items: center;
      margin-left: 10px;
      flex-shrink: 0;
    }
    .wrapper {
      height: 100% !important;
    }
    .ChatPage-main {
      .header {
        display: none;
        .name {
          font-size: 18px;
        }
      }
      .main {
        width: 100% !important;
        flex: 1;
      }

      .chatPage {
        height: 100% !important;
        overflow: initial;
        display: flex;
        justify-content: flex-end;
        flex-flow: column;
      }
      .web__main {
        padding: 20px;
        width: 100%;
        .item_msg {
          > span {
            br:last-child {
              display: none;
            }
          }
          p {
            font-weight: bold;
            font-size: 16px;
          }
          pre {
            position: relative;
            padding: 15px 20px;
            overflow: hidden;
            font-size: 16px;
            background-color: #1a1a1a12;
            border-radius: 4px;
            line-height: 1.5;
            transition: 0.2s all ease-in-out;
            margin: -12px 0 0;
          }
          code {
            padding: 0.4em;
            margin: 0;
          }
        }
      }
      .web__main-user {
        cite {
          display: none;
        }
      }
      .web__main-text {
        margin-top: 0 !important;
      }
      .tools {
        display: none;
      }
      .toolBox {
        box-shadow: initial;
        min-height: initial;
        height: initial;
      }
    }
  }

  .arrow-down-icon {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 0 20px #ddd;
    transition: 0.2s all ease-in;
  }
  .arrow-down-icon:hover {
    box-shadow: 0 0 20px #dadada;
    color: #000000;
  }
  .mt10 {
    margin-top: 10px;
  }
}
.home-page-hide {
  ::v-deep .ChatPage {
    min-height: initial !important;
    .taleBox {
      display: none;
    }
  }
  ::v-deep .ChatPage-main {
    .header {
      display: none !important;
    }
  }
}
</style>
<style>
@import "@/assets/styles/reset.scss";
</style>
