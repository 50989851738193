var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'home-page': true, 'home-page-hide': !_vm.nowChat.length }},[_c('div',{staticClass:"flex-0"},[_c('div',{staticClass:"app-title",style:({
        paddingTop: _vm.nowChat.length ? '10px' : '80px',
        marginBottom: _vm.nowChat.length ? '0px' : '60px',
      })},[_vm._v(" 超智云 AI ")]),(!_vm.nowChat.length)?_c('div',{staticClass:"app-types flex flex-row-center"},_vm._l((_vm.typeList),function(type){return _c('div',{key:type.name,staticClass:"app-type"},[_c('div',{staticClass:"app-type-info flex flex-column flex-center"},[_c('i',{class:['app-type-icon', type.icon]}),_c('div',{staticClass:"line-one"},[_vm._v(_vm._s(type.name))])]),_vm._l((type.child),function(child,childIndex){return _c('div',{key:`chat-${childIndex}`,staticClass:"app-type-item flex flex-column",on:{"click":function($event){return _vm.submitChat(
              {
                text: child,
                oldText: child,
                format: true,
                mine: true,
              },
              _vm.nowChat.length
            )}}},[_c('div',{staticClass:"app-type-item-txt flex-1 line-two"},[_vm._v(" "+_vm._s(child)+" ")]),_vm._m(0,true)])})],2)}),0):_vm._e()]),(_vm.show.preview)?_c('el-image-viewer',{attrs:{"on-close":_vm.closeImage,"url-list":[this.show.previewImage]}}):_vm._e(),_c('JwChat-index',{attrs:{"config":_vm.config,"show-right-box":false,"taleList":_vm.nowChat,"toolConfig":_vm.tool,"placeholder":"回车或点击发送即发送消息给 AI","scrollType":"scroll"},on:{"clickTalk":_vm.bindEnter,"enter":_vm.bindEnter},model:{value:(_vm.inputMsg),callback:function ($$v) {_vm.inputMsg=$$v},expression:"inputMsg"}},[_c('div',{staticClass:"arrow-down-icon",attrs:{"slot":"downBtn"},slot:"downBtn"},[_c('el-icon',{attrs:{"name":"arrow-down"}})],1),_c('div',{staticClass:"enter-body flex flex-col-center w-full",attrs:{"slot":"enter"},slot:"enter"},[_c('div',{staticClass:"flex flex-col-center flex-1"},[_c('div',{staticClass:"enter-content w-full"},[_vm._v(" "+_vm._s(_vm.inputMsg)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputMsg),expression:"inputMsg"}],staticClass:"t_area",attrs:{"auto-height":"","disable-default-padding":"","placeholder":"回车或点击发送即发送消息给 AI"},domProps:{"value":(_vm.inputMsg)},on:{"input":function($event){if($event.target.composing)return;_vm.inputMsg=$event.target.value}}})]),_c('i',{staticClass:"el-icon-s-promotion flex-0",attrs:{"slot":"enterBtn"},on:{"click":_vm.bindEnter},slot:"enterBtn"})])]),_c('vip-dialog',{model:{value:(_vm.show.member),callback:function ($$v) {_vm.$set(_vm.show, "member", $$v)},expression:"show.member"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-0 flex flex-row-right"},[_c('i',{staticClass:"el-icon-right"})])
}]

export { render, staticRenderFns }