import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import "@/assets/styles/element-variables.scss";

import "@/assets/styles/index.scss"; // global css
import Chat from "jwchat";
/* 在 0.2.041 之前的版本需要引入 css */
// import 'jwchat/lib/JwChat.css';
Vue.use(Chat);
Vue.prototype.jump = function (path) {
  if (this.$route.path !== path) {
    router.push(path);
  }
};
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
