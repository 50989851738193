<template>
  <div class="app-layout flex">
    <app-aside></app-aside>
    <div class="app-content flex-1">
      <div class="app-body flex flex-column">
        <transition mode="out-in" name="fade-transform">
          <keep-alive>
            <router-view :key="key" />
          </keep-alive>
        </transition>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</template>

<script>
import AppFooter from "@/layout/footer.vue";
import AppAside from "@/layout/aside.vue";

export default {
  name: "app-layout",
  components: { AppAside, AppFooter },
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-layout {
  .app-content {
    height: 100vh;
    width: 100%;
    overflow: auto;
    padding: 0 20px;
  }
  .app-body {
    margin: 0 auto;
    max-width: 1256px;
    height: 100%;
  }
}
</style>
