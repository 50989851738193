import axios, { stream } from "@/utils/request";
// 公共接口
// 加载初始化
export const init = (params) => axios.get("/api/common/init", { params });
// 上传文件
export const upload = (data) => axios.post("/api/common/upload", data);
// 邮箱验证码接口
// 发送验证码
export const sendEmailCode = (data) => axios.post("/api/ems/send", data);
// 检测验证码
export const checkEmailCode = (data) => axios.post("/api/ems/check", data);
// axios.post("/api/index/index", data),
// 首页分类
export const category = (data) => axios.post("/api/index/category", data);
// 获取AI助手数据
export const getAiTool = (data) => axios.post("/api/index/assistant", data);
// 获取历史记录
export const getHistory = (data) => axios.post("api/index/chatLog", data);
// 创建聊天记录
export const sendRecord = (data) =>
  axios.post("/api/index/uploadChatRecord", data);
// 收藏
export const onCollect = (data) => axios.post("/api/index/collect", data);
// 手机短信接口
// 发送验证码
export const sendPhoneCode = (data) => axios.post("/api/sms/send", data);
// 检测验证码
export const checkPhoneCode = (data) => axios.post("/api/sms/check", data);
// Token接口
// 检测Token是否过期
export const checkToken = (params) => axios.get("/api/token/check", { params });
// 刷新Token
export const refresh = (params) => axios.get("/api/token/refresh", { params });
// 会员接口
// 会员中心
export const getUserInfo = (params) => axios.get("/api/user/index", { params });
// 会员登录
export const login = (data) => axios.post("/api/user/login", data);
// 手机验证码登录
export const mobilelogin = (data) => axios.post("/api/user/mobilelogin", data);
// 注册会员
export const register = (data) => axios.post("/api/user/register", data);
// 退出登录
export const logout = (data) => axios.post("/api/user/logout", data);
// 修改会员个人信息
export const profile = (data) => axios.post("/api/user/profile", data);
// 修改邮箱
export const changeemail = (data) => axios.post("/api/user/changeemail", data);
// 修改手机号
export const changemobile = (data) =>
  axios.post("/api/user/changemobile", data);
// 第三方登录
export const third = (data) => axios.post("/api/user/third", data);
// 重置密码
export const resetpwd = (data) => axios.post("/api/user/resetpwd", data);
// PC端微信扫码登录获取二维码
export const getLoginQrCode = (params) =>
  axios.get("/api/user/getLoginQrCode", { params });
// 轮询获取扫码登录结果
export const queryQrCodeStatus = (data) =>
  axios.post("/api/user/queryQrCodeStatus", data);
// 微信公众号授权登录
export const oauthLogin = (data) => axios.post("/api/user/oauthLogin", data);
// 验证接口
// 检测邮箱
export const check_email_available = (data) =>
  axios.post("/api/validate/check_email_available", data);
// 检测用户名
export const check_username_available = (data) =>
  axios.post("/api/validate/check_username_available", data);
// 检测昵称
export const check_nickname_available = (data) =>
  axios.post("/api/validate/check_nickname_available", data);
// 检测手机
export const check_mobile_available = (data) =>
  axios.post("/api/validate/check_mobile_available", data);
// 检测手机
export const check_mobile_exist = (data) =>
  axios.post("/api/validate/check_mobile_exist", data);
// 检测邮箱
export const check_email_exist = (data) =>
  axios.post("/api/validate/check_email_exist", data);
// 检测手机验证码
export const check_sms_correct = (data) =>
  axios.post("/api/validate/check_sms_correct", data);
// 检测邮箱验证码
export const check_ems_correct = (data) =>
  axios.post("/api/validate/check_ems_correct", data);
// Vip相关接口
// 在售会员卡列表
export const getVipList = (params) =>
  axios.get("/api/vip/cardList", { params });
// 创建订单
export const createOrder = (data) => axios.post("/api/vip/createOrder", data);
// 获取支付参数
export const getPayInfo = (data) => axios.post("/api/vip/payInfo", data);
// 查询订单支付结果
export const queryOrder = (data) => axios.post("/api/vip/queryOrder", data);
// 支付后的微信回调
export const getOrderResult = (params) =>
  axios.get("/api/vip/order_notify", { params });
// Wechat
// 消息及事件处理
export const onWxchat = (params) => axios.get("/api/wechat/index", { params });
// 我的收藏
export const myCollect = (data) => axios.post("/api/index/myCollect", data);
// 意见反馈
export const feedback = (data) => axios.post("/api/index/feedback", data);
//  用户协议
export const yhxy = (data) => axios.post("/api/index/yhxy", data);
//  隐私政策
export const yszc = (data) => axios.post("/api/index/yszc", data);

export const aiResult =  (data, callback) => {
  stream("/api/index/index", data, callback);
}
