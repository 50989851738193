<template>
  <div class="guide-page">
    <div class="guide_con" data-v-4bc4c720="" data-v-62018f79="">
      <h2 class="text_center" data-v-62018f79="">教程超智云 AI 上手指南</h2>
      <h4 data-v-62018f79="">
        写在开头：本文旨在帮助未使用过超智云
        AI，以及正在使用的朋友一些入门和进阶的指引。
      </h4>
      <h4 data-v-62018f79="">*本文不讨论注册，本站无超智云 AI注册账号。</h4>
      <h4 data-v-62018f79="">超智云 AI概述</h4>
      <view class="" data-v-62018f79=""
        ><ol data-v-62018f79="">
          超智云
          AI是一款由OpenAI开发的大型语言模型，主要功能是回答用户的问题和完成各种语言任务，如对话生成、文本摘要、翻译、生成文本等。它使用了先进的深度学习技术和海量的语言数据进行训练，可以在各种语言领域提供高质量的语言处理服务。它可以做到:
        </ol>
        <ul data-v-62018f79="">
          <li data-v-62018f79="">
            回答问题: 超智云 AI
            拥有广泛的知识库，能够回答各种话题的问题，比如历史、科学、文化等。它能够提供详细的信息和细节，并且能够根据上下文调整回答。
          </li>
          <li data-v-62018f79="">
            对话能力: 超智云 AI
            能够参与自然对话，可以与人类进行多轮交流。它通过文本或语音，理解人类的意图，使对话更加自然。
          </li>
          <li data-v-62018f79="">
            寻求方案:当人类遇到困难或问题时，超智云 AI
            可以提供有效的解决方案。它可以识别人类的需求，根据问题提供适当的帮助。
          </li>
          <li data-v-62018f79="">
            学习工具: 超智云 AI
            作为一种学习工具，人类可以通过与它交流来提高语言能力和知识水平。它提供丰富的信息和知识，不断学习和更新，为人类提供更多的学习机会。
          </li>
          <li data-v-62018f79="">
            自动化:超智云 AI
            可以作为客服或人工智能助手使用。它能够与人类进行交流，帮助解决问题，提高工作效率和满意度。
          </li>
        </ul></view
      >
      <h4 data-v-62018f79="">
        超智云 AI 4.0 和 超智云 AI 3.5 是 OpenAI
        推出的两个版本的自然语言处理模型，它们有以下区别:
      </h4>
      <ul data-v-62018f79="">
        <li data-v-62018f79="">
          模型大小: 超智云 AI 4.0 模型比 超智云 AI 3.5 更大，参数量为 6.7
          亿个，而 超智云 AI 3.5 的参数量为 1.75 亿个。
        </li>
        <li data-v-62018f79="">
          训练数据: 超智云 AI 4.0
          使用的是更全面的训练数据，包括更多的网页文本和书籍，以及更多的多语言文本。而超智云
          AI 3.5 则主要使用英语维基百科等数据。
        </li>
        <li data-v-62018f79="">
          生成能力: 由于 超智云 AI 4.0
          模型更大，训练数据更全面，因此在生成文本的能力上超智云 AI 4.0 比
          超智云 AI3.5 更强大，能够生成更准确、更有逻辑性的文本。
        </li>
        <li data-v-62018f79="">
          速度和性能:由于模型大小的不同，超智云 AI 4.0 相比 超智云 AI 3.5
          的推理速度更慢，需要更长的时间来生成文本。但是 超智云 AI 4.0
          在生成文本的质量上更好。
        </li>
        <li data-v-62018f79="">
          总的来说，超智云 AI 4.0 是 超智云 AI 3.5
          的升级版，具备更强大的生成能力和更高的生成质量，但由于模型大小的原因，速度相对较慢。
        </li>
      </ul>
      <h4 data-v-62018f79="">对于超智云 AI的评述</h4>
      <ul data-v-62018f79="">
        <li data-v-62018f79="">超智云 AI不能完全替代我们工作</li>
        <li data-v-62018f79="">
          但借助它的辅助，能给我们的工作生活带来显著的效率提升
        </li>
        <li data-v-62018f79="">能拓展出更丰富的学习可能性</li>
      </ul>
      <h4 data-v-62018f79="">超智云 AI能做什么？</h4>
      <ul data-v-62018f79="">
        <h4 data-v-62018f79="">（1）准确翻译</h4>
        <li data-v-62018f79="">翻译源可以是跨语言内容，各种语言混杂</li>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/1.PNG"
        />
        <h4 data-v-62018f79="">（2）改写论文表达</h4>
        <li data-v-62018f79="">
          帮助改写混乱的文段，优化表达、提升文字学术性、易读性
        </li>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/2.PNG"
        />
        <h4 data-v-62018f79="">（3） 帮助提炼大量文字</h4>
        <li data-v-62018f79="">
          输入的文本不需要管排版和格式，只要是文本都可以帮助提炼。但是，该版本的限制了最大字符量，因此信息不能太大，但可以复杂
        </li>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/3.png"
        />
        <h4 data-v-62018f79="">（4）论文审稿意见回复</h4>
        <li data-v-62018f79="">可融合你修改的具体内容，精确回复审稿意见</li>
        <li data-v-62018f79="">在初步意见回复后，可通过改写帮助优化表达</li>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/4.png"
        />
        <h4 data-v-62018f79="">（5）快速生成代码</h4>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/5.png"
        />
        <h4 data-v-62018f79="">（6）快速构建Excel复杂函数公式</h4>
        <li data-v-62018f79="">
          写出公式后，还能通过自然语言让他根据你变化的需求进行公式修改
        </li>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/6.png"
        />
        <h4 data-v-62018f79="">（7）快速入门某一项技能</h4>
        <li data-v-62018f79="">
          可以根据大纲中每一点，进行进一步提问，通过结构分解掌握技能细节
        </li>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/7.png"
        />
        <h4 data-v-62018f79="">（8）模拟求职面试</h4>
        <li data-v-62018f79="">可以帮助你回顾和提升面试表现</li>
        <li data-v-62018f79="">
          通过合适的提问，可以从面试者和面试官的角度给你面试建议
        </li>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/8.png"
        />
        <h4 data-v-62018f79="">（9）帮忙写小作文</h4>
        <img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/9.png"
        /><img
          class="imgs"
          data-v-62018f79=""
          src="https://ai-1254946451.cos.ap-shanghai.myqcloud.com/pic/10.png"
        />
      </ul>
      <h4 data-v-62018f79="">超智云 AI提问技巧:</h4>
      <ul data-v-62018f79="">
        <li data-v-62018f79="">
          参考资料: 超智云 AI版《学会提问》，经典著作，强烈建议阅读
        </li>
        <li data-v-62018f79="">
          简介
          <ul data-v-62018f79="">
            <li data-v-62018f79="">
              目的:本书主要内容是教读者如何制定清晰、有效的超智云
              AI提示，以引导富有吸引力和信息性的对话
            </li>
            <li data-v-62018f79="">
              基础: 在介绍超智云
              AI的基础知识，如如何工作以及它的特点之后，将深入介绍有效提示原则和实际技巧
            </li>
            <li data-v-62018f79="">
              技巧: 本书还将介绍如何避免常见的错误，以及解决使用超智云
              AI时可能遇到的问题的技巧
            </li>
          </ul>
        </li>
        <li data-v-62018f79="">
          技巧简述
          <ul data-v-62018f79="">
            <li data-v-62018f79="">
              目标明确:
              谈话要有一个明确的目标或目的。心中有一个具体的目标将有助于保持谈话的重点和轨道
            </li>
            <li data-v-62018f79="">
              有针对性的问题:使用具体的、有针对性的问题，而不是开放式问题。这将有助于引导谈话朝着特定的方向0进行，避免漫无边际或偏离主题
            </li>
            <li data-v-62018f79="">
              简洁的提示:避免在单个提示中包含太多信息。保持提示简洁、重点突出，避免包含不必要的细节或说明
            </li>
            <li data-v-62018f79="">
              清晰的语言:使用超智云
              AI易于理解的清晰、简洁的语言。避免使用行话或模棱两可的语言
            </li>
            <li data-v-62018f79="">
              过渡性短语:使用过渡短语从一个话题顺利地转移到另一个话题。这有助于保持连贯性，使对话顺畅进行
            </li>
            <li data-v-62018f79="">
              能力局限: 请注意超智云
              AI的功能和限制。避免要求它做超出其能力范围的事情，并准备在必要时调整提示
            </li>
            <li data-v-62018f79="">
              充当"技巧:指定模型在对话中的角色，能帮助更好地回答问题
            </li>
          </ul>
        </li>
        <li data-v-62018f79="">
          提问例子 (由超智云 AI应用上述技巧生成)
          <ul data-v-62018f79="">
            <li data-v-62018f79="">
              "假设你作为一个具有50年经验的量子计算研究专家，量子纠缠在量子计算机的运作中扮演了多关键的角0色，它对量子计算机与经典计算机的潜在计算能力有什么影响?"
            </li>
          </ul>
        </li>
      </ul>
      <h4 data-v-62018f79="" style="background: #ec0003; display: inline-block">
        注意事项
      </h4>
      <ul data-v-62018f79="">
        <li data-v-62018f79="">
          中文能力: 超智云
          AI对于中文语境的复杂内容理解能力欠佳，因此建议问题尽量明确
        </li>
        <li data-v-62018f79="">
          先翻译:可先让超智云 AI把你提问的中文问题翻译成英文
        </li>
        <li data-v-62018f79="">
          再提问:再通过它帮你翻译好的英文问题，向其提问
        </li>
        <li data-v-62018f79="">
          如果对chatqpt的回答不满意以及回答错误的，可以直接连续上下文对话回复它:
          你回答错误，请重新回复
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "guide-page",
};
</script>

<style lang="scss" scoped>
.guide-page {
  color: #2c3e50;
  h4 {
  }
  li {
    line-height: 28px;
  }
}
</style>
