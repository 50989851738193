import Vue from "vue";
import Vuex from "vuex";
import { getUserInfo } from "@/api";
import { getToken, removeToken } from "@/utils/auth";
import { guid } from "@/utils/common";
// 将marked 引入
import { marked }from 'marked';
// 引入代码高亮样式
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    chat: [],
    chats: {},
    chatActive: "",
    chatCount: 0,
  },
  getters: {
    // 当前对话列表
    nowChat(state) {
      return state.chats[state.chatActive] || [];
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    addChat(state, { chatId, callback }) {
      state.chat.push(chatId);
      state.chatActive = chatId;
      state.chats[chatId] = [];
      callback && callback();
    },
    // 直接添加对话
    pushChat(state, chat) {
      let callback = chat.callback;
      delete chat.callback;
      if (!state.chatActive) {
        const chatId = guid(18);
        state.chat.push(chatId);
        state.chatActive = chatId;
      }
      if (!state.chats[state.chatActive]) {
        state.chats[state.chatActive] = [];
      }
      state.chats[state.chatActive].push(chat);
      callback && callback(state.chatActive);
      state.chatCount++;
    },
    // 修改ai对话
    editChat(state, { index, text, chatActive, oldText, status, callback }) {
      if (!state.chats[chatActive][index]) {
        state.chats[chatActive][index] = {
          text: { text: text || "<p>AI正在思考您的问题...</p>" },
          mine: false,
          oldText: "AI正在思考您的问题...",
          state: false,
          img: "https://oss.zwk999.com/chatgpt/static/img/th.png",
        };
        if (text) {
          return;
        }
      }
      if (status === undefined) {
        return;
      }
      if (status) {
        state.chatCount++;
      }
      state.chats[chatActive][index].state = status;
      if (text) {
        const opt = {
          gfm: true,
          breaks: true,
          smartLists: true,
          highlight: function(code) {
            return require('highlight.js').highlightAuto(code).value;
          }
        };
        if (
          state.chats[chatActive][index].oldText === "AI正在思考您的问题..."
        ) {
          state.chats[chatActive][index].text.text = marked(oldText, opt) ||text;
          state.chats[chatActive][index].oldText = oldText;
        } else {
          state.chats[chatActive][index].oldText += oldText;
          state.chats[chatActive][index].text.text = marked(state.chats[chatActive][index].oldText, opt);
        }
      }
      callback && callback(state.chats[chatActive][index]);
    },
    setChatActive(state, chatId) {
      state.chatActive = chatId;
    },
    removeChat(state, chatId) {
      const index = state.chat.findIndex((row) => chatId === row);
      if (index > -1) {
        state.chat.splice(index, 1);
      }
      if (state.chatActive) {
        state.chatActive = state.chat[index] || "";
      }
      state.chatCount++;
    },
    clearAllChat(state) {
      state.chat = [];
      state.chatActive = "";
    },
    clearUser(state) {
      removeToken();
      state.user = {};
    },
  },
  actions: {
    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        if (!getToken()) {
          return;
        }
        getUserInfo()
          .then((res) => {
            commit("setUser", res.data);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
});
